import React from 'react';

import Head from 'components/Head';
import MainLayout from 'components/MainLayout';
import { RouteComponentProps } from '@reach/router';

import { button } from 'style';
import { css } from '@emotion/core';

import background from 'images/404.svg';
import mm2 from 'images/mm2.gif';

export default function NotFound(_: RouteComponentProps) {
  return (
    <MainLayout>
      <Head title="404" />
      <section css={hero}>
        <h2 css={title}>404</h2>
        <p>Oh no! You must be lost, because there's no page here.</p>
        <img src={mm2} css={lostImage} alt="" />
        <a href="/" css={button({ kind: 'neutral' })}>
          Go Home
        </a>
      </section>
    </MainLayout>
  );
}

const lostImage = css`
  max-height: 25rem;
  border-radius: 0.5rem;
  margin: 0 auto 3rem auto;
`;

const hero = css`
  background: center url(${background}) no-repeat;
  background-size: cover;
  text-align: center;
  min-height: 60rem;
  padding: 6rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const title = css`
  margin-bottom: 1.2rem;
`;
